import { useSelector } from "react-redux";
import { FunctionSelectTabCommon } from "./functionSelectTab";
import { getTabList } from "../../configs/app-layout";
import { selectSelectedApp } from "../AppSelectTab/selectedAppSlice";

export const FunctionSelectTabWrapper = () => {
  const store = useSelector(selectSelectedApp);
  console.log("FunctionSelectTabWrapper");

  if (Object.keys(store).length > 0 && "appType" in store) {
    const appType = store.appType;
    const tabList = getTabList(appType).map(
      (tabName) => tabName[0].toUpperCase() + tabName.substring(1)
    );

    //dispatch(functionChange(defaultTab));

    //return <></>;
    return <FunctionSelectTabCommon tabs={tabList} appType={appType} />;
  } else {
    return <></>;
  }
};
