import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Landing } from "./pages/landing";
import { Home } from "./pages/home";
import { Lab } from "./pages/lab/Lab";
import { Inventory } from "./pages/inventory/Inventory";

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum, goerli } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
const { chains, provider } = configureChains(
  [mainnet, polygon, optimism, arbitrum, goerli],
  [
    infuraProvider({ apiKey: "d85b23f501584a4aa0a6cfdf6ad3d8bc" }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})

function App() {
  return (
    <div className="App">
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <Router>
            <Routes>
              <Route path="/" element={<Landing data={this} />} />
              <Route path="/home" element={<Home data={this} />} />
              <Route path="/lab" element={<Lab data={this} />} />
              <Route path="/inventory" element={<Inventory data={this} />} />
            </Routes>
          </Router>
        </RainbowKitProvider>
      </WagmiConfig>
    </div >
  );
}

export default App;
