import * as Web3 from "web3";
import * as ethers from "ethers";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { getChainId } from "./wallet/wallet";
import axios from "axios";
import { PINATA_JWT } from "./constants";
import { NETWORKS } from "./wallet/networks";


export const isMobile = () =>
  /Mobi/i.test(window.navigator.userAgent) ||
  /iPhone|iPod|iPad/i.test(navigator.userAgent);

export const objectMap = (object, mapFn) => {
  return Object.keys(object).reduce((result, key) => {
    result[key] = mapFn(object[key]);
    return result;
  }, {});
};

export const normalizeURL = (u) => new URL(u).host.replace("www.", "");

export const parseTxError = (error) => {
  try {
    return {
      code: error.code ?? JSON.parse(`{${error.message.split("{")[1]}`).code,
      message: error.message.split("{")[0].trim(),
    };
  } catch (parse_error) {
    console.log("Failed to parse error code and message");
    console.log("Original error:", error);
    return {
      code: undefined,
      message: error?.toString(),
    };
  }
};

// Avoid big number errors without using external libraries
export const formatValue = (v) =>
  v.toLocaleString("fullwide", {
    useGrouping: false,
  });

export const roundToDecimal = (n, d) => {
  return +n.toFixed(d);
};

export const setButtonText = (btn, text) => {
  if (btn.childElementCount > 0) {
    btn.children[0].textContent = text;
  } else {
    btn.textContent = text;
  }
};

export const formatWalletAddress = (address) => {
  return address && `${address.substring(0, 6)}...${address.substring(38)}`;
};

export const convertWeiToETH = (wei) => {
  return Web3.utils.fromWei(wei, "ether");
};

export const convertEthToWei = (eth) => {
  return Web3.utils.toWei(eth, "ether");
};

export const etherscanLink = async () => {
  const chainID = await getChainId();
  return NETWORKS[chainID].blockExplorerURL;
};

export const isValidAddr = (address) => {
  return Web3.utils.isAddress(address);
};

export const encodeInit = async (name, symbol, collectionSize) => {
  const ABI = [
    "function initialize(string name, string symbol, uint256 collectionSize)",
  ];
  const iface = new ethers.utils.Interface(ABI);
  const result = await iface.encodeFunctionData("initialize", [
    name,
    symbol,
    collectionSize,
  ]);
  console.log("encodeInit", result);
  return result;
};

export const getProof = (contract, address, leafNodes, round) => {
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  const leaf = Web3.utils.soliditySha3(
    { t: "address", v: contract },
    { t: "address", v: address },
    { t: "uint256", v: round }
  );
  const proof = merkleTree.getHexProof(leaf);
  // console.log("PROOF", proof);
  return proof;
};

export const getRoot = (leafNodes) => {
  const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

  const rootHash = merkleTree.getRoot().toString("hex");
  // console.log("ROOT", merkleTree.toString(), rootHash);
  return rootHash;
};

export const getLeafNodes = (contract, addresses, round) => {
  return addresses.map((address) =>
    Web3.utils.soliditySha3(
      { t: "address", v: contract },
      { t: "address", v: address },
      { t: "uint256", v: round }
    )
  );
};

export const getLeafNodesByInfo = (contract, infos) => {
  return infos.map((info) =>
    Web3.utils.soliditySha3(
      { t: "address", v: contract },
      { t: "address", v: info.address },
      { t: "uint256", v: info.round }
    )
  );
};

export const converEpochToDate = (timestamp) => {
  if (timestamp !== '0') {
    var date = new Date(timestamp * 1000);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    return year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds
  }
  else {
    return "Not set"
  }
}

export const handleFiletoIpfs = async (selectedFile) => {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    formData.append('file', selectedFile)

    const metadata = JSON.stringify({
      name: "image",
    });
    formData.append('pinataMetadata', metadata);

    const options = JSON.stringify({
      cidVersion: 0,
    })
    formData.append('pinataOptions', options);

    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        maxBodyLength: "Infinity",
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          Authorization: PINATA_JWT
        }
      });
      console.log(res.data);
      resolve(res.data)
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
}

export const handleJsonToIpfs = async (jsonInput) => {
  return new Promise(async (resolve, reject) => {
    const data = JSON.stringify(jsonInput);

    const config = {
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': PINATA_JWT
      },
      data: data
    };
    try {
      const res = await axios(config);

      console.log(res.data);
      resolve(res.data);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  })
}