import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    imageFormOpen: false,
};

const imageUploadSlice = createSlice({
    name: "imageUpload",
    initialState,
    reducers: {
        openUploadForm: (state) => {
            // console.log("openUploadForm");
            state.imageFormOpen = true
        },
        closeUploadForm: (state) => {
            state.imageFormOpen = false
        }
    }
})

export const { openUploadForm, closeUploadForm } = imageUploadSlice.actions;

export default imageUploadSlice.reducer;
