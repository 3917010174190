import { ConnectWalletBtn } from "../../components/ConnectWalletBtn/connectButton";
import { AppSelectTabWrapper } from "../../components/AppSelectTab/appSelectTabWrapper";
import { CreateAppForm } from "../../components/CreateAppForm/createAppForm";
import { FunctionSelectTabWrapper } from "../../components/FunctionSelectTab/functionSelectTabWrapper";
import { MainBoxWrapper } from "../../components/MainBox/mainBoxWrapper";
import { useEffect } from "react";
import { connectToWallet, getWalletAddress } from "../../utils/wallet/wallet";
import { userChange } from "../../components/ConnectWalletBtn/connectSlice";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { WALLET_CONNECT_SUCCESS_MSG } from "../../utils/msg/messages";
import { NETWORKS } from "../../utils/wallet/networks";
import { useSelector } from "react-redux";

import "./index.css";
import "../../utils/font.css";

export function Home() {
  console.log("Hello!");
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const chainId = useSelector((state) => state.connect.chainId);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")) {
        await connectToWallet();
        const userAddr = await getWalletAddress();
        dispatch(userChange(userAddr));
      }
    })();
    if (location.state && location.state.from_landing) {
      enqueueSnackbar(WALLET_CONNECT_SUCCESS_MSG, {
        variant: "success",
      });
    }
    console.log("chainId", chainId);
  }, []);

  return (
    <div className={"storeWrapper"}>
      <CreateAppForm />
      <AppSelectTabWrapper />
      <div className="rightTab">
        <div className="upBox">
          <div className="right-top-wrapper">
            <button className="common-button whiteButton storePosition">
              {NETWORKS[chainId].name}
            </button>
            <ConnectWalletBtn sytleMode={"storePosition"} />
          </div>
          <FunctionSelectTabWrapper />
        </div>
        <MainBoxWrapper />
      </div>
    </div>
  );
}
