import { MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { lazy, Suspense } from "react";

export const Lab = () => {
  const componentName = "Test";

  const DynamicComponent = lazy(() => import(`./${componentName}${appType}`));
  let [appType, setAppType] = useState(1);

  const handleAppTypeChange = (event) => {
    setAppType(event.target.value);
  };

  return (
    <>
      <Select value={appType} onChange={handleAppTypeChange}>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
      </Select>

      <Suspense fallback={<div>Loading...</div>}>
        <DynamicComponent />
      </Suspense>
    </>
  );
};
