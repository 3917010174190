import "./appSelectTab.css";
import { useSelector, useDispatch } from "react-redux";
import { selectedAppChange, selectSelectedAppIdx } from "./selectedAppSlice";
import { openCreateForm } from "../CreateAppForm/createAppSlice";
import { selectAppList } from "./appListSlice";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { getDefaultTab } from "../../configs/app-layout";
import { functionChange } from "../FunctionSelectTab/functionSelectSlice";

var initializing = true;

export const AppSelectTab = () => {
  const selectedIdx = useSelector(selectSelectedAppIdx);
  const storeList = useSelector(selectAppList);

  const dispatch = useDispatch();

  const switchToDefaultTab = (app) => {
    const defaultTab = getDefaultTab(app.appType);
    dispatch(
      functionChange(defaultTab[0].toUpperCase() + defaultTab.substring(1))
    );
  };

  if (storeList.length > 0 && initializing) {
    dispatch(selectedAppChange({ app: storeList[0], index: 0 }));
    console.log("Dispatched ", storeList[0]);
    switchToDefaultTab(storeList[0]);
    initializing = false;
  }

  const handleClick = async (id) => {
    dispatch(selectedAppChange({ app: storeList[id], index: id }));
    switchToDefaultTab(storeList[id]);
  };
  const handleCreate = () => {
    dispatch(openCreateForm());
  };

  return (
    <div className="leftTab">
      {storeList.map((storeObj, idx) => {
        return (
          <button
            key={idx}
            className={
              selectedIdx === idx
                ? "unselectedButton selectedButton"
                : "unselectedButton"
            }
            onClick={() => {
              handleClick(idx);
            }}
          >
            {storeObj.name}
          </button>
        );
      })}
      <button
        className={"unselectedButton"}
        onClick={() => {
          handleCreate();
        }}
      >
        <MdOutlineLibraryAdd />
        Add app
      </button>
    </div>
  );
};
