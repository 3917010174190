import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    configureFormOpen: false,
    urlFormopen: false,
};

const configureFormSlice = createSlice({
    name: "configureForm",
    initialState,
    reducers: {
        openConfigureForm: (state) => {
            state.configureFormOpen = true
        },
        closeConfigureForm: (state) => {
            state.configureFormOpen = false
        },
        openUriForm: (state) => {
            state.urlFormopen = true
        },
        closeUriForm: (state) => {
            state.urlFormopen = false
        }
    }
})

export const { openConfigureForm, closeConfigureForm, openUriForm, closeUriForm } = configureFormSlice.actions;

export default configureFormSlice.reducer;
