import { callViewFunction, readParam } from "../cao";

// TODO: this can be getNumOfItems(proxyAddr)
export async function getItemIdList(proxyAddr) {
  const boundry = await readParam(proxyAddr, "boundry");
  return Array.from(Array(parseInt(boundry)).keys());
}

export async function getSupplyOf(productId, proxyAddr) {
  return await callViewFunction(proxyAddr, "supplyLimit", [productId]);
}

export async function getNumMinted(productId, proxyAddr) {
  return await callViewFunction(proxyAddr, "tokenMinted", [productId]);
}

export async function getBalanceOf(productId, proxyAddr) {
  const supply = await getSupplyOf(productId, proxyAddr);
  const numMinted = await getNumMinted(productId, proxyAddr);
  return supply - numMinted;
}

export async function getUrl(tokenId, proxyAddr) {
  return await callViewFunction(proxyAddr, "uri", [tokenId]);
}

export async function getPriceOf(tokenId, proxyAddr) {
  return await callViewFunction(proxyAddr, "mintPrice", [tokenId]);
}

export async function getMintOnOf(tokenId, proxyAddr) {
  return await callViewFunction(proxyAddr, "isMintOn", [tokenId]);
}
