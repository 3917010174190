import { LoginBox } from "../../components/LoginBox/LoginBox"
import { OrderMidWire } from "../../components/OrdersMidwire/OrderMidWire";
import "./Inventory.css";

export const Inventory = () => {
    return (
        <div className="inventory-wrapper">
            <h1>CatchOn Inventory</h1>
            <LoginBox />
            <OrderMidWire />
        </div>
    )
}