import { lazy, Suspense, useState } from "react";
import "./createAppForm.css";
import { useSelector, useDispatch } from "react-redux";
import { selectConnect } from "../ConnectWalletBtn/connectSlice";
import { appUpdate, selectAppList } from "../AppSelectTab/appListSlice";
import { APP_TYPE_BASE_NFT } from "../../utils/constants";
import { closeCreateForm } from "./createAppSlice";
import { useStyles } from "../common/muiStyles";
import { loadStoreListData } from "../../utils/dataLoader";
import { Box, Dialog, FormControl, MenuItem, Select } from "@mui/material";
import { supportedAppTypeList } from "../../configs/app-layout";

export const CreateAppForm = () => {
  const appFormOpen = useSelector((state) => state.appCreate.appFormOpen);
  const dispatch = useDispatch();
  const ownerAddress = useSelector(selectConnect);
  const storeList = useSelector(selectAppList);
  const classes = useStyles();

  const supportedAppType = supportedAppTypeList;

  const [appType, setAppType] = useState(APP_TYPE_BASE_NFT);
  const [txnStatus, setTxnStatus] = useState("");
  const [appNameError, setAppNameError] = useState(false);

  const handleAppTypeChange = (event) => {
    setAppType(event.target.value);
  };

  const DynamicCreateFormComponent = lazy(() =>
    import(`../../apps/${appType}/createAppForm/createAppForm`)
  );

  const handleClose = async (isConfirm = false) => {
    dispatch(closeCreateForm());
    //setAppNameError(false);
    console.log("handleClose", isConfirm);
    if (isConfirm) {
      const data = await loadStoreListData(storeList, ownerAddress);
      dispatch(appUpdate(data));
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 20 },
      }}
      onClose={() => {
        //setTxnStatus("");
        handleClose(false);
      }}
      open={appFormOpen}
    >
      <div id="create-body">
        <h1 className="italicize">Get started</h1>
        <p className="italicize">
          Create non-fungible tokens (NFTs) to easily and securely monetize and
          trade your unique digital assets on blockchain platforms.
        </p>
        <Box sx={{ minWidth: 200 }}>
          <div className="create-form-gap">
            <FormControl fullWidth size="small" className={classes.root}>
              <h3>App type</h3>
              <Select
                displayEmpty
                className="box-text-space"
                value={appType}
                onChange={handleAppTypeChange}
              >
                {supportedAppType.map((type, idx) => {
                  return (
                    <MenuItem value={type.name}>{type.displayText}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Suspense fallback={<div>Loading...</div>}>
              <DynamicCreateFormComponent handleClose={handleClose} />
            </Suspense>
          </div>
        </Box>
      </div>
    </Dialog>
  );
};
