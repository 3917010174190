import "./connectButton.css";
import "../../utils/font.css";
import {
  connectToWallet,
  initFactoryContract,
  getWalletAddress,
  verifyAccount,
} from "../../utils/wallet/wallet";
import { formatWalletAddress } from "../../utils/utils";
import { useSelector, useDispatch } from "react-redux";
import { userChange, selectConnect, userVerify } from "./connectSlice";
import { useNavigate } from "react-router-dom";
import { FACTORY_CONTRACT_ADDRESS } from "../../utils/wallet/abiHelper";
import { useSnackbar } from "notistack";
import {
  USER_DENIED_MESSAGE_SIG_MSG,
  USER_SIG_CANNOT_BE_VERIFIED_MSG,
  WALLET_CONNECT_FAILURE_MSG,
} from "../../utils/msg/messages";

export function ConnectWalletBtn({ sytleMode }) {
  const address = useSelector(selectConnect);
  const dispatch = useDispatch();

  return (
    <button
      className={"common-button whiteButton " + sytleMode}
      onClick={async () => {
        await connectToWallet();
        const userAddr = await getWalletAddress();
        dispatch(userChange(userAddr));
      }}
    >
      {address === "CONNECT WALLET"
        ? "Connect Wallet"
        : formatWalletAddress(address)}
    </button>
  );
}

export function ConnectWalletBtnAndSignIn() {
  const isVerified = useSelector((state) => state.connect.isVerified);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (
    <button
      className={"common-button blackButton center-button home-page-button"}
      onClick={async () => {
        await connectToWallet();
        const userAddr = await getWalletAddress();
        console.log(`userAddr ${userAddr}`);
        dispatch(userChange(userAddr));
        var identityVerified = false;
        var reason = "";
        if (isVerified) {
          identityVerified = true;
        } else {
          try {
            identityVerified = await verifyAccount();
          } catch (error) {
            if (error.message.indexOf(USER_DENIED_MESSAGE_SIG_MSG) != -1) {
              reason = USER_DENIED_MESSAGE_SIG_MSG;
            } else {
              reason = USER_SIG_CANNOT_BE_VERIFIED_MSG;
            }
          }
        }
        if (identityVerified) {
          dispatch(userVerify());
          await initFactoryContract(FACTORY_CONTRACT_ADDRESS);
          navigate("/home", { state: { from_landing: true } });
        } else {
          enqueueSnackbar(`${WALLET_CONNECT_FAILURE_MSG}: ${reason}`, {
            variant: "error",
          });
        }
      }}
    >
      LAUNCH APP
    </button>
  );
}
