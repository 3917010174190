import { useEffect, useState } from "react";
import { getAllOrders, putOrder } from "../../utils/dataLoaderApi";
import { useSelector } from "react-redux";
import { formatOrders, formatPutBody } from "./orderUtils";
import { ExportTable } from "../Table/ExportTable";
import "./OrderMidWire.css";
import { useSnackbar } from "notistack";

const tmpProxyAddr = "0xAF088Af14d0E8F605b89B2FeA6c4965605FD3A33";

export const OrderMidWire = () => {
    const signature = useSelector(state => state.loginBox.signature);
    const address = useSelector(state => state.loginBox.userAddress);
    const chain = useSelector(state => state.loginBox.chain);
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState([]);

    const fetchAllOrders = async () => {
        const orders = await getAllOrders(address, tmpProxyAddr, chain.id, signature);
        console.log("orders", orders);
        const formattedOrders = formatOrders(orders);
        console.log("formattedOrders", formattedOrders);
        setData(formattedOrders);
    }

    const handlePutOrder = async (values) => {
        console.log("put order");
        const { body, id } = formatPutBody(values);
        console.log("body", body, id);
        try {
            putOrder(id, body, address, chain.id, signature, () => { })
        } catch (error) {
            console.log("error", error);
            enqueueSnackbar(`Error putting order ${error.message}`, {
                variant: 'error',
            })
        }
    }

    useEffect(() => {
        if (signature) {
            console.log("signature", signature);
            fetchAllOrders();
        }
    }, [signature])
    return (
        <div className="order-midwire-wrapper">
            <ExportTable data={data} handlePutOrder={handlePutOrder} />
        </div>
    )
}