import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    address: "CONNECT WALLET",
    isVerified: false,
    chainId: 5,
};

export const connectButtonSlice = createSlice({
    name: "connect",
    initialState,
    reducers: {
        userChange: (state, action) => {
            state.address = action.payload
        },
        userVerify: (state) => {
            state.isVerified = true
        },
        resetVerify: (state) => {
            state.isVerified = false
        },
        chainIdChange: (state, action) => {
            state.chainId = action.payload
        },
    }
})

export const { userChange, userVerify, resetVerify, chainIdChange } = connectButtonSlice.actions;

export const selectConnect = (state) => state.connect.address;

export default connectButtonSlice.reducer;
