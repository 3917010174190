import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appList: [], // list of objects of store meta data
};

const appListSlice = createSlice({
  name: "appList",
  initialState,
  reducers: {
    appUpdate: (state, action) => {
      state.appList = action.payload;
    },
  },
});

export const { appUpdate } = appListSlice.actions;

export const selectAppList = (state) => state.appList.appList;

export default appListSlice.reducer;
