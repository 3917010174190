import * as _ from 'lodash';

export const formatOrders = (orders) => {
    const formattedOrder = orders.map(order => {
        const id = _.get(order, ["id"], "");
        const status = _.get(order, ["status"], "");
        const recipient = _.get(order, ["recipient"], {});
        const first_name = _.get(recipient, ["first_name"], "");
        const last_name = _.get(recipient, ["last_name"], "");
        const email = _.get(recipient, ["email"], "");
        const phone = _.get(recipient, ["phone"], "");
        const street = _.get(recipient, ["street"], "");
        const apt = _.get(recipient, ["apt"], "");
        const city = _.get(recipient, ["city"], "");
        const state = _.get(recipient, ["state"], "");
        const zipcode = _.get(recipient, ["zipcode"], "");
        const country = _.get(recipient, ["country"], "");
        const transaction_hash = _.get(order, ["transaction_hash"], "");
        const redeemed_tokens = _.get(order, ["redeemed_tokens"], []);
        const token_id = _.get(redeemed_tokens, [0, "token_id"], "");
        const redeemed_number = _.get(redeemed_tokens, [0, "redeemed_number"], "");
        const receipt_token_ids = JSON.stringify(_.get(redeemed_tokens, [0, "receipt_token_ids"], []));
        const carrier = _.get(order, ["carrier"], "");
        const tracking_number = _.get(order, ["tracking_number"], "");
        return {
            id,
            status,
            carrier,
            tracking_number,
            token_id,
            redeemed_number,
            receipt_token_ids,
            first_name,
            last_name,
            street,
            apt,
            city,
            state,
            zipcode,
            country,
            email,
            phone,
            transaction_hash,
        }
    })
    const uniqueTxns = [];
    var ordersReverse = [...formattedOrder];
    return ordersReverse.filter(order => {
        const transaction_hash = _.get(order, ["transaction_hash"], "");
        const isDuplicate = uniqueTxns.includes(transaction_hash);

        if (!isDuplicate) {
            uniqueTxns.push(transaction_hash);
            return true;
        }
        return false;
    });
}

export const formatPutBody = (order) => {
    const id = _.get(order, ["id"], "");
    const status = _.get(order, ["status"], "");
    const first_name = _.get(order, ["first_name"], "");
    const last_name = _.get(order, ["last_name"], "");
    const street = _.get(order, ["street"], "");
    const apt = _.get(order, ["apt"], "");
    const city = _.get(order, ["city"], "");
    const state = _.get(order, ["state"], "");
    const zipcode = _.get(order, ["zipcode"], "");
    const country = _.get(order, ["country"], "");
    const email = _.get(order, ["email"], "");
    const phone = _.get(order, ["phone"], "");
    const carrier = _.get(order, ["carrier"], "");
    const tracking_number = _.get(order, ["tracking_number"], "");
    const recipient = {
        first_name,
        last_name,
        street,
        apt,
        city,
        state,
        zipcode,
        country,
        email,
        phone,
    };
    const body = {
        status,
        carrier,
        tracking_number,
        recipient,
    };
    return { body, id }
}