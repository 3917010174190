import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, IconButton, MenuItem, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { Edit } from '@mui/icons-material';

export const ExportTable = ({ data, handlePutOrder }) => {
    const [tableData, setTableData] = useState(() => data);

    const [validationErrors, setValidationErrors] = useState({});

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'OrderID',
                enableEditing: false, //disable editing on this column
                size: 40,
            },
            {
                accessorKey: 'status',
                header: 'Status',
                size: 80,
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: statusMenus.map((state) => (
                        <MenuItem key={state} value={state}>
                            {state}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'carrier',
                header: 'Carrier',
                size: 80,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'carrier',
                }),
            },
            {
                accessorKey: 'tracking_number',
                header: 'Tracking Number',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'tracking_number',
                }),
            },
            {
                accessorKey: 'token_id',
                header: 'Token ID',
                enableEditing: false, //disable editing on this column
                size: 40,
            },
            {
                accessorKey: 'redeemed_number',
                header: 'Redeemed Number',
                enableEditing: false, //disable editing on this column
                size: 40,
            },
            {
                accessorKey: 'receipt_token_ids',
                header: 'Receipt Token IDs',
                enableEditing: false, //disable editing on this column
                size: 120,
            },
            {
                accessorKey: 'first_name',
                header: 'First Name',
                size: 100,
            },
            {
                accessorKey: 'last_name',
                header: 'Last Name',
                size: 100,
            },
            {
                accessorKey: 'street',
                header: 'Street',
                size: 200,
            },
            {
                accessorKey: 'apt',
                header: 'Apt',
                size: 80,
            },
            {
                accessorKey: 'city',
                header: 'City',
                size: 80,
            },
            {
                accessorKey: 'state',
                header: 'State/Province',
                size: 80,
            },
            {
                accessorKey: 'zipcode',
                header: 'ZipCode/Postal Code',
                size: 120,
            },
            {
                accessorKey: 'country',
                header: 'Country',
                size: 100,
            },
            {
                accessorKey: 'email',
                header: 'Email',
                size: 120,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'email',
                }),
            },
            {
                accessorKey: 'phone',
                header: 'Phone',
                size: 120,
            },
            {
                accessorKey: 'transaction_hash',
                header: 'Transaction Hash',
                enableEditing: false, //disable editing on this column
                size: 160,
            },
        ]);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            //send/receive api updates here, then refetch or update local table data for re-render
            handlePutOrder(values);
            setTableData([...tableData]);
            exitEditingMode(); //required to exit editing mode and close modal
        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    useEffect(() => {
        setTableData(data);
    }, [data]);

    return (
        <MaterialReactTable
            columns={columns}
            data={tableData}
            enableRowSelection
            positionToolbarAlertBanner="bottom"
            enableEditing
            onEditingRowSave={handleSaveRowEdits}
            onEditingRowCancel={handleCancelRowEdits}
            renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <Tooltip arrow placement="left" title="Edit">
                        <IconButton onClick={() => table.setEditingRow(row)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                >
                    <Button
                        color="primary"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Rows
                    </Button>
                    <Button
                        disabled={table.getRowModel().rows.length === 0}
                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                        onClick={() => handleExportRows(table.getRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Page Rows
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        }
                        //only export selected rows
                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                </Box>
            )}
        />
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const statusMenus = ["Submitted", "Processing", "Completed", "Cancelled", "Shipped"];