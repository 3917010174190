import "../common/common.css";
import "./LoginBox.css";
import { useAccountModal, useChainModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useEnsName, useNetwork, useSwitchNetwork, useProvider, useSigner } from "wagmi";
import { formatWalletAddress } from "../../utils/utils";
import { SignLoading } from "./SignLoading";
import { dispatchSignMessage } from "./loginDispatch";
import { utils } from "ethers";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateChain, updateUserAddress } from "./LoginBoxSlice";

const timeRound = 100000;

export const LoginBox = () => {

    const { openConnectModal } = useConnectModal();
    const { openAccountModal } = useAccountModal();
    const { openChainModal } = useChainModal();
    const dispatch = useDispatch();
    const provider = useProvider();
    const { data: signer } = useSigner()
    const { address, isConnected } = useAccount();
    const { data, isError } = useEnsName({
        address,
    })
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork();

    const user = isError || (!data) ? formatWalletAddress(address) : data;

    const signFetchOrderMessage = async () => {
        const curTime = Math.round(Date.now() / timeRound);
        const nonce = await provider.getTransactionCount(address);
        const hash = utils.keccak256(utils.toUtf8Bytes(address + nonce + curTime));
        console.log(address, nonce, curTime, hash);
        dispatchSignMessage(`Check Order History in CatchOn ${hash}`);
    }

    useEffect(() => {
        if (isConnected && address && provider) {
            console.log("address", address);
            signFetchOrderMessage();
        }
    }, [isConnected, address, provider])

    useEffect(() => {
        if (chain) {
            dispatch(updateChain(chain));
        }
    }, [chain])

    useEffect(() => {
        if (address) {
            dispatch(updateUserAddress(address));
        }
    }, [address])

    return (
        <>
            <div className="login-box">
                <div className="user-modal-title">
                    {openConnectModal && (
                        <button onClick={openConnectModal} type="button" className="common-button login-button">
                            Connect wallet
                        </button>
                    )}

                    {openAccountModal && (
                        <button onClick={openAccountModal} type="button" className="common-button login-button">
                            {isConnected && <div>{user}</div>}
                        </button>
                    )}
                </div>
                {openChainModal && (
                    <button onClick={openChainModal} type="button" className="common-button chain-button">
                        {chain?.name}
                    </button>
                )}
            </div>
            <SignLoading />
        </>
    )
}