import {
  getListOfAppsOwnedBy,
  getMetaDataOfProxy,
} from "./contract_access_object/readerCao";
import {
  getUrl,
  getSupplyOf,
  getMintOnOf,
  getNumMinted,
  getPriceOf,
} from "./contract_access_object/eStore/readerCao";
import { initProxyContract } from "./wallet/wallet";
import {
  ECOMERCE_CONTRACT_ADDRESS,
  ERC721_LOGIC_CONTRACT_ADDRESS,
  ERC721_BLANK_CONTRACT_ADDRESS,
} from "./wallet/abiHelper";
import { readJsonObj as readJsonObjFromJsonBin } from "./jsonHelper/jsonBinHelper";
import { convertWeiToETH } from "./utils";
import { APP_TYPE_BASE_NFT, APP_TYPE_ECOMMERCE, APP_TYPE_ERC721 } from "./constants";

export const loadStoreListData = async (oldList, ownerAddress) => {
  const newAddrList = await getListOfAppsOwnedBy(ownerAddress);
  //console.log("new storeAddrList", newAddrList);

  const oldAddrList = oldList.map((obj) => obj.proxyAddress);
  //console.log("old storeAddrList", oldAddrList);

  if (
    newAddrList.length === oldAddrList.length &&
    newAddrList.every((element, index) => element === oldAddrList[index])
  ) {
    return oldAddrList;
  }

  // update store obj list
  const newStoreList = await Promise.all(
    newAddrList.map(async (proxyAddr) => {
      const storeMeta = await getMetaDataOfProxy(proxyAddr);
      // TODO: DEV-39
      switch (storeMeta.logicAddress) {
        case "0x3c9E629d4c0917f85655f555c2bf33755A981D8B":
          await initProxyContract(proxyAddr, ERC721_LOGIC_CONTRACT_ADDRESS);
          storeMeta.appType = APP_TYPE_ERC721;
          break;
        case "0xF8A7a09821bE27CD4f6394E1a82646eD4e6c88aB":
          await initProxyContract(proxyAddr, ECOMERCE_CONTRACT_ADDRESS);
          storeMeta.appType = APP_TYPE_ECOMMERCE;
          break;
        case "0xA3d4EC8CD33D86d9219E4557F5fb0c73C372Fa4e":
        case "0x349053D7FC806bD8BcCc9627766Fb539C3eE8818":
        case "0xFd2A688AD995C39e363bfa74D84CC9BF933e94f5":
        case "0xe496107B2D6a5A4fD771461e01D422E0C1c9194C":
          await initProxyContract(proxyAddr, ERC721_BLANK_CONTRACT_ADDRESS);
          storeMeta.appType = APP_TYPE_BASE_NFT;
          break;
        default:
          console.error(
            `logictAddress ${storeMeta.logicAddress} not supported!`
          );
      }
      storeMeta.proxyAddress = proxyAddr;
      return storeMeta;
    })
  );

  return newStoreList;
};

export const loadProductListData = async (itemList, proxyAddress) => {
  const products = await Promise.all(
    itemList.map(async (tokenId) => {
      const tokenUrl = await getUrl(tokenId, proxyAddress);
      const metaDataObj = await readJsonObjFromJsonBin(tokenUrl);
      const name = metaDataObj.name;
      const img = metaDataObj.img;
      const description = metaDataObj.description;
      const supply = await getSupplyOf(tokenId, proxyAddress);
      const isSaleOn = await getMintOnOf(tokenId, proxyAddress);
      const sold = await getNumMinted(tokenId, proxyAddress);
      const balance = supply - sold;
      const price = convertWeiToETH(await getPriceOf(tokenId, proxyAddress));

      return {
        tokenId: tokenId,
        image: img,
        isSaleOn: isSaleOn,
        name: name,
        sold: sold,
        supply: supply,
        price: price,
        description: description,
        balance: balance,
      };
    })
  );
  return { proxyAddress: proxyAddress, productList: products };
};
