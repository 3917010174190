import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  functionName: "product",
};

const functionSelectSlice = createSlice({
  name: "functionSelect",
  initialState,
  reducers: {
    functionChange: (state, action) => {
      state.functionName = action.payload.toLowerCase();
    },
  },
});

export const { functionChange } = functionSelectSlice.actions;
export const functionSelector = (state) => state.functionSelect.functionName;

export default functionSelectSlice.reducer;
