import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
        },
        "& .MuiInputBase-root": {
            margin: '0px'
        }
    }
});