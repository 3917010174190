export const WALLET_CONNECT_SUCCESS_MSG = "Wallet Connected Successfully!";
export const WALLET_CONNECT_FAILURE_MSG = "Can't Connect to wallet!";
export const USER_SIG_CANNOT_BE_VERIFIED_MSG =
  "User signature cannot be verified";
export const USER_DENIED_MESSAGE_SIG_MSG = "User denied message signature";

export const NEW_APP_CREATE_SUCCESS_MSG = "New App Created Successfully!";
export const NEW_APP_CREATE_FAILURE_MSG = "Failed to create new app";

export const USER_DENIED_TNX_SIG_MSG = "User denied transaction signature";

export const UPLOAD_JSON_FAILURE = "Failed to upload JSON to cloud";

export const NEW_PRODUCT_CREATION_SUCCESS_MSG =
  "New product created successfully!";
export const NEW_PRODUCT_CREATION_FAILURE_MSG = "Failed to add a product";

export const TRANSACTION_TIMEOUT_MSG =
  "Transaction Timeout after timeout_s seconds";

export const UPLOAD_OBJECT_TO_IPFS_SUCCESS_MSG =
  "{object_type} uploaded to IPFS successfully!";
export const UPLOAD_OBJECT_TO_IPFS_FAILURE_MSG =
  "Failed to upload {object_type} to IPFS";

export const COPIED_TO_CLIPBOARD_SUCCESS_MSG = "Copied to clibboard!";
