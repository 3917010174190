import { createSlice } from "@reduxjs/toolkit";

/**
 * a container of products, with the structure
 * products : {
 *     proxyAddress1 : [
 *         {
 *           tokenId: 1,
 *           name: "some product",
 *           ...
 *         },
 *         ...
 *     ]
 * }
 *
 */
const initialState = {
  products: {},
};

const productUpdateSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    productUpdate: (state, action) => {
      const proxyAddress = action.payload.proxyAddress;
      const productList = action.payload.productList;
      state.products[proxyAddress] = productList;
    },
  },
});

export const { productUpdate } = productUpdateSlice.actions;

export const selectProductDict = (state) => state.products.products;

export default productUpdateSlice.reducer;
