import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedApp: {},
  selectedAppIdx: 0,
};

const selectedAppSlice = createSlice({
  name: "selectedApp",
  initialState,
  reducers: {
    selectedAppChange: (state, action) => {
      state.selectedApp = action.payload.app;
      state.selectedAppIdx = action.payload.index;
    },
  },
});

export const { selectedAppChange } = selectedAppSlice.actions;

export const selectSelectedApp = (state) => state.selectedApp.selectedApp;
export const selectSelectedAppIdx = (state) => state.selectedApp.selectedAppIdx;

export default selectedAppSlice.reducer;
