import { API_SQL_DB } from "./constants";
import * as _ from "lodash";

export const getAllOrders = async (signer, contractAddr, chainId, signature) => {
    const orderRes = await fetch(`${API_SQL_DB}order?signer=${signer}&app=${contractAddr}&chain=${chainId}&sig=${signature}&expanded=true`, {
        method: "GET",
        headers: {
            "Cache-Control": "no-cache",
        }
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                throw new Error(`Network response error. ${response.status}`)
            }
        })
        .then(async (response) => {
            const orders = _.get(response, ["orders"]);
            return (orders);
        })
        .catch((error) => {
            console.error(error);
        });
    return orderRes;
}

export const putOrder = async (orderId, body, signer, chainId, signature, handleSuccess) => {
    if (body && signer && chainId && signature) {
        await fetch(`${API_SQL_DB}order/${orderId}?signer=${signer}&chain=${chainId}&sig=${signature}`, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    throw new Error(`Network response error. ${response.status}`)
                }
            })
            .then(response => {
                console.log(response);
                handleSuccess();
            })
            .catch(e => {
                console.error(e);
                const msg = e.message;
                throw new Error(msg);
            });
    }
    else {
        throw new Error("Missing body, signer, app, chainId, or signature");
    }
}