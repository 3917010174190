const layout = {
  eCommerce: {
    tabs: ["products", "buyers", "integration"],
    default: "products",
    displayName: "eCommerce Store",
  },
  erc721: {
    tabs: ["NFTs", "Integration"],
    default: "NFTs",
    displayName: "NFT Collection",
  },
  erc721blank: {
    tabs: ["Configuration", "Integration"],
    default: "Configuration",
    displayName: "Base NFT Collection",
  },
};

/**
 * Source of truth of a list of supported app types
 */
export const appTypeList = Object.getOwnPropertyNames(layout);

export const supportedAppTypeList = Object.getOwnPropertyNames(layout).map(
  (type) => {
    return { name: type, displayText: layout[type].displayName };
  }
);

export const getTabList = (appType) => layout[appType].tabs;

export const getDefaultTab = (appType) => layout[appType].default;
