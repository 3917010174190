import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productFormOpen: false,
};

const createProductSlice = createSlice({
    name: "productCreate",
    initialState,
    reducers: {
        openCreateForm: (state) => {
            state.productFormOpen = true
        },
        closeCreateForm: (state) => {
            state.productFormOpen = false
        }
    }
})

export const { openCreateForm, closeCreateForm } = createProductSlice.actions;

export default createProductSlice.reducer;
