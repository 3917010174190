import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { selectSelectedApp } from "../AppSelectTab/selectedAppSlice";

export const MainBoxWrapper = () => {
  const store = useSelector(selectSelectedApp);
  const functionName = useSelector(
    (state) => state.functionSelect.functionName
  );
  console.log("MainboxWrapper", store);
  if (Object.keys(store).length > 0 && "appType" in store) {
    const appType = store.appType;
    console.log("Mainbox of ", appType);
    const DynamicMainBoxComponent = lazy(() =>
      import(`../../apps/${appType}/tabs/${functionName}/tab`)
    );

    return (
      <>
        <Suspense fallback={<div>Loading...</div>}>
          <DynamicMainBoxComponent />
        </Suspense>
      </>
    );
  } else {
    return (
      <h1 style={{ width: "100%", textAlign: "center", color: "gray" }}>
        Click{" "}
        <span style={{ fontStyle: "italic", color: "black" }}>Add app</span> to
        create my fisrt NFT app
      </h1>
    );
  }
};
