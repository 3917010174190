const URL = "https://api.jsonbin.io/v3/b";
const MASTER_KEY =
  "$2b$10$dqlAtoTsUa/3uBuikG7lt.fwYoYl4AsqnfvXBlHGmwuYUOo0uB0su";
const ACCESS_KEY =
  "$2b$10$cXBbikGRAkE0Xs/PZu7VeO58l3GEEmXa8q.3Nmo80BCOX2Vp2/qiu";

export const uploadJsonObj = async (jsonObj) => {
  const res = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Master-Key": MASTER_KEY,
    },
    body: JSON.stringify(jsonObj),
  });

  const resJson = await res.json();
  console.log(`resJson: ${resJson}`);
  const binId = resJson.metadata.id;
  const jsonUrl = "https://api.jsonbin.io/v3" + `/b/${binId}`;
  return jsonUrl;
};

export const readJsonObj = async (jsonUrl) => {
  const res = await fetch(jsonUrl, {
    method: "GET",
    headers: {
      "X-Access-Key": ACCESS_KEY,
      "X-Master-Key": MASTER_KEY,
    },
  });

  const resJson = await res.json();

  const obj = resJson.record;
  return obj;
};
