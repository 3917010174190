import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appFormOpen: false,
};

const createAppSlice = createSlice({
  name: "appCreate",
  initialState,
  reducers: {
    openCreateForm: (state) => {
      state.appFormOpen = true;
    },
    closeCreateForm: (state) => {
      state.appFormOpen = false;
    },
  },
});

export const { openCreateForm, closeCreateForm } = createAppSlice.actions;

export default createAppSlice.reducer;
