
export const GAS_INCREASE = 1.16;

export const MINTED_CHECK_CAP = 5000;

export const FACTORY_CONTRACT_ADDR =
  //"0x031dC5b491B441a588BF6BaDECb511fA67856Ede";
  "0x64d7957dA876BeB219D92697601C91eb176D2755"; // this is the new factory which can return metadata

export const IMPLEMENTATION_ADDRESS =
  "0x697ab37b8d975340Db4077083db685826af67503";

export const PROXY_FACTORY_FUNCTION_MAPPER = {
  getProxyAddressList: "getProxiesOwnedBy",
  createProxy: "createProxy",
};

export const PROXY_FUNCTION_MAPPER = {
  name: "name",
  getMetaData: "getAppMetaData",
};

// Configs
export const ENABLE_MOCK = false;
export const API_DB = "https://11xykht95a.execute-api.us-west-1.amazonaws.com/";
export const IPFS_GATEWAY = "https://anzh.mypinata.cloud/ipfs/";
export const MOCK_API = false;
export const API_SQL_DB = MOCK_API ? "http://localhost:3000/ecommerce/" : "https://shield-swart.vercel.app/ecommerce/";

// App Extensibility
export const APP_TYPE_ECOMMERCE = "eCommerce";
export const APP_TYPE_ERC721 = "erc721";
export const APP_TYPE_BASE_NFT = "erc721blank";
export const APP_TYPE_MEMBERSHIP = "membership";

const toAppType = (name, text) => {
  return {
    name: name,
    displayText: text,
  };
};

export const SUPPORTED_APP_TYPE = [
  toAppType(APP_TYPE_BASE_NFT, "Base NFT Collection"),
  toAppType(APP_TYPE_ECOMMERCE, "eCommerce Store"),
  toAppType(APP_TYPE_ERC721, "NFT Collection"),
  // toAppType(APP_TYPE_MEMBERSHIP, "NFT Membership"),
];

export const PINATA_JWT = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI4ZDQ4MGUyMC1hNDI2LTQ3MzctOTFjNC05YzFhN2EwZDc5NmEiLCJlbWFpbCI6ImFuemhhbmdodXN0QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiJhYjBkY2IwNDVmN2I1NDQzNmY1NiIsInNjb3BlZEtleVNlY3JldCI6ImJlMDBhNmJkMmZkZDE2NzI2NjRiZDcxODA1ZWFjZWFlYjdkZTkzNjBkNTQ2YjA2ZGEyZjE1NzQwYjBjY2I5MjAiLCJpYXQiOjE2Njk0OTI1NTB9.H2Oag9o070oAgY-SblwgajPBaHlTNEy28n6uu-TrrJI`;
