import { combineReducers } from "redux";

// common reducers
import connectReducer from "../components/ConnectWalletBtn/connectSlice";
import selectedAppReducer from "../components/AppSelectTab/selectedAppSlice";
import createAppReducer from "../components/CreateAppForm/createAppSlice";
import functionSelectReducer from "../components/FunctionSelectTab/functionSelectSlice";
import appListReducer from "../components/AppSelectTab/appListSlice";

// Ecommerce reducers
import createProductReducer from "../apps/eCommerce/dialogs/CreateProductForm/createProductSlice";
import productUpdateReducer from "../apps/eCommerce/mainBox/ProductGallery/productUpdateSlice";

// NFT BLANK reducers
import configureFormReducer from "../apps/erc721blank/configureForm/configureFormSlice";
import imageUploadFormReducer from "../apps/erc721blank/imageUploadForm/ImageUploadSlice";

// LoginBox reducers
import loginBoxReducer from "../components/LoginBox/LoginBoxSlice";

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  connect: connectReducer,
  selectedApp: selectedAppReducer,
  appList: appListReducer,
  appCreate: createAppReducer,
  functionSelect: functionSelectReducer,

  // Ecommerce reducers
  productCreate: createProductReducer,
  products: productUpdateReducer,
  configureForm: configureFormReducer,
  imageUploadForm: imageUploadFormReducer,
  loginBox: loginBoxReducer,
});

export default rootReducer;
