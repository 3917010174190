import fetch from "node-fetch";
import { API_DB, APP_TYPE_BASE_NFT, APP_TYPE_ECOMMERCE } from "../constants";
import * as _ from "lodash";
import { computeAddress } from "ethers/lib/utils";
/**
 * This file will be untimately replaced by Database calls.
 */

const chainAddress = {
  "5": {
    chainId: 5,
    chainName: "Goerli",
    factoryAddr: "0x037B75E0541281E0b515681dC5263a1A29EDc5A2",
    erc721LogicAddr: "0x697ab37b8d975340Db4077083db685826af67503",
    [APP_TYPE_ECOMMERCE]: "0xd497Ca3b0F87f2dc330dDce7EC79Ddc09927A602",
    [APP_TYPE_BASE_NFT]: "0xe496107B2D6a5A4fD771461e01D422E0C1c9194C",
  },
  "11155111": {
    chainId: 11155111,
    chainName: "Sepolia",
    factoryAddr: "0x942d0529c8478AB991D68280229142Cb7fE89A75",
    erc721LogicAddr: "0x3c9E629d4c0917f85655f555c2bf33755A981D8B",
    [APP_TYPE_ECOMMERCE]: "0xF8A7a09821bE27CD4f6394E1a82646eD4e6c88aB",
    [APP_TYPE_BASE_NFT]: "0xFd2A688AD995C39e363bfa74D84CC9BF933e94f5",
  },
};

var abi = {};

// TODO: this should change based on the connected wallet chain
let SELECTED_CHAIN = "11155111";

export let FACTORY_CONTRACT_ADDRESS =
  chainAddress[SELECTED_CHAIN]["factoryAddr"];
export let ERC721_LOGIC_CONTRACT_ADDRESS =
  chainAddress[SELECTED_CHAIN]["erc721LogicAddr"];
export let ECOMERCE_CONTRACT_ADDRESS =
  chainAddress[SELECTED_CHAIN][APP_TYPE_ECOMMERCE];
export let ERC721_BLANK_CONTRACT_ADDRESS =
  chainAddress[SELECTED_CHAIN][APP_TYPE_BASE_NFT];

export const setChainId = (chainId) => {
  SELECTED_CHAIN = parseInt(chainId).toString();
  FACTORY_CONTRACT_ADDRESS = chainAddress[SELECTED_CHAIN]["factoryAddr"];
  ERC721_LOGIC_CONTRACT_ADDRESS = chainAddress[SELECTED_CHAIN]["erc721LogicAddr"];
  ECOMERCE_CONTRACT_ADDRESS = chainAddress[SELECTED_CHAIN][APP_TYPE_ECOMMERCE];
  ERC721_BLANK_CONTRACT_ADDRESS = chainAddress[SELECTED_CHAIN][APP_TYPE_BASE_NFT];
  console.log("ERC721_BLANK_CONTRACT_ADDRESS", ERC721_BLANK_CONTRACT_ADDRESS);
}
// TODO: when converting this to db calls, remember to add "async" here and "await" at callers
// TODO: select contract abi based on chainId: is it necessary?
export const fetchAbiOfContractAt = async (contractAddr, chainId) => {
  if (contractAddr in abi) {
    return abi[contractAddr];
  } else {
    return await fetch(API_DB + "abi/" + chainId + "/" + contractAddr, {
      method: "GET",
    })
      .then((response) => response.json())
      .then(async (response) => {
        const dataFromDb = _.get(response, ["Item", "Abi"]);
        if (dataFromDb === undefined) {
          alert(
            `No ABI data found: contract address=${computeAddress}, chain ID=${chainId}`
          );
        }
        console.log("ABI", dataFromDb);
        if (dataFromDb) {
          abi[contractAddr] = JSON.parse(dataFromDb);
          return JSON.parse(dataFromDb);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
};
