import { useDispatch, useSelector } from "react-redux";
import { selectConnect } from "../ConnectWalletBtn/connectSlice";
import { selectAppList, appUpdate } from "./appListSlice";
import { AppSelectTab } from "./appSelectTab";
import { useEffect } from "react";
import { loadStoreListData } from "../../utils/dataLoader";
import { initFactoryContract } from "../../utils/wallet/wallet";

export const AppSelectTabWrapper = () => {
  const ownerAddress = useSelector(selectConnect);
  const storeList = useSelector(selectAppList);

  const dispatch = useDispatch();

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      await initFactoryContract();
      const data = await loadStoreListData(storeList, ownerAddress);
      console.log("loadStoreListData", data);
      dispatch(appUpdate(data));
    };
    if (ownerAddress) {
      // call the function
      fetchData()
        // make sure to catch any error
        .catch(console.error);

      const id = setInterval(async () => {
        await loadStoreListData(storeList, ownerAddress);
      }, 5000);
      return () => clearInterval(id);
    }
  }, [ownerAddress]);

  return <AppSelectTab />;
};
