import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userAddress: "",
    chain: {},
    message: "",
    signClick: false,
    signature: "",
};

const loginBoxSlice = createSlice({
    name: "loginBox",
    initialState,
    reducers: {
        updateUserAddress: (state, action) => {
            state.userAddress = action.payload;
        },
        updateChain: (state, action) => {
            state.chain = action.payload;
        },
        updateMessage: (state, action) => {
            state.message = action.payload;
        },
        updateSignClick: (state, action) => {
            state.signClick = action.payload;
        },
        updateSignature: (state, action) => {
            state.signature = action.payload;
        },
    },
});

export const {
    updateUserAddress,
    updateChain,
    updateMessage,
    updateSignClick,
    updateSignature,
} = loginBoxSlice.actions;

export default loginBoxSlice.reducer;
