import "./functionSelectTab.css";
import { functionChange } from "./functionSelectSlice";
import { useDispatch, useSelector } from "react-redux";

export const FunctionSelectTabCommon = (props) => {
  console.log("Inside FunctionSelectTabCommon");
  console.log("props", props);
  const tabs = props.tabs;
  const functionName = useSelector(
    (state) => state.functionSelect.functionName
  );

  const dispatch = useDispatch();

  const handleClick = (id) => {
    dispatch(functionChange(id));
  };

  return (
    <div className="selectTab">
      {tabs.map((tab, idx) => {
        return (
          <button
            className={
              functionName === tab
                ? "functionButton enableFunButton"
                : "functionButton"
            }
            onClick={() => {
              handleClick(tab);
            }}
            key={idx}
          >
            {tab}
          </button>
        );
      })}
    </div>
  );
};
