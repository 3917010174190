import {
  ConnectWalletBtn,
  ConnectWalletBtnAndSignIn,
} from "../../components/ConnectWalletBtn/connectButton";
import "./index.css";
import "../../utils/font.css";

export function Landing() {
  return (
    <div className={"landingWrapper"}>
      <div className="header">
        <ConnectWalletBtn sytleMode={"walletPosition"} />
      </div>
      <div className={"landingContent"}>
        <p className={"p1"}>CatchOn</p>
        <p className={"p2"}>No-code solution for</p>
        <p className={"p2"}>NFT eCommerce</p>
        <ConnectWalletBtnAndSignIn />
      </div>
    </div>
  );
}
